import React, {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import codes from 'country-calling-code';
import './addCaptainDialog.scss';
import {
  TrackwellButton, TrackwellInput, useTrackwellValidation, TrackwellExpandableDropdown,
} from '@trackwell/trackwell-components';
import parsePhoneNumber, {
  isValidPhoneNumber,
} from 'libphonenumber-js';
import { getEnv, getLocalization } from '../../../lang/lang';

const filterOutText = (value) => (typeof value !== 'undefined' ? value.replace(/\D/g, '') : value);

const isNumber = (value) => {
  const val = value;
  if (typeof value === 'undefined' || value.length === 0) {
    return true;
  }
  // if (val[val.length - 1] === '.' || val[val.length - 1] === ',') {
  //   val += '0';
  // }
  // (/^\d+\.\d+$|^\d+$/.test(val))
  return /^\d+$/.test(val);
};

const AddCaptainDialog = memo(({ onSuccess }) => {
  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  const webEnv = useMemo(() => {
    return getEnv();
  }, []);

  const [name, setName] = useState();
  const [kennitala, setKennitala] = useState();
  const [country, setCountry] = useState(localization.modalDefaultCountryCode);
  const [number, setNumber] = useState();

  const codeLookup = useMemo(() => {
    const lookup = {};
    codes.forEach(({ countryCodes, isoCode2 }) => {
      lookup[`+${countryCodes}`] = isoCode2;
    });
    return lookup;
  }, []);

  useEffect(() => {
    if (typeof number !== 'undefined' && !isNumber(number)) {
      setNumber(filterOutText(number));
    }
  }, [number]);

  useEffect(() => {
    if (typeof kennitala !== 'undefined' && !isNumber(kennitala)) {
      setKennitala(filterOutText(kennitala));
    }
  }, [kennitala]);

  const customValidation = useMemo(() => ({
    phoneValidation: {
      function: ({ value = (typeof country === 'undefined' ? '' : country) + (typeof number === 'undefined' ? '' : number) }) => {
        if (typeof country === 'undefined') {
          return false;
        }
        const phoneNumber = parsePhoneNumber(value);
        if (typeof phoneNumber === 'undefined') {
          return false;
        }
        return isValidPhoneNumber(phoneNumber.formatInternational(), codeLookup[country]);
      },
      message: ({ value = (typeof country === 'undefined' ? '' : country) + (typeof number === 'undefined' ? '' : number) }) => {
        if (typeof value === 'undefined' || value.length === 0) {
          return 'phone number cannot be empty.';
        }
        return `'${value}' is not a valid phone number.`;
      },
    },
  }), [codeLookup, country, number]);

  const { field, setField, submit } = useTrackwellValidation(customValidation);

  const selectCountry = useCallback(({ value }) => {
    setCountry(value);
  }, []);

  const addCaptain = useCallback(async () => {
    if (await submit()) {
      onSuccess({ name, kennitala, phone: (typeof country === 'undefined' ? '' : country) + (typeof number === 'undefined' ? '' : number) });
    }
  }, [submit, onSuccess, name, kennitala, number, country]);

  const onNameInput = useCallback(({ value }) => {
    setName(value);
  }, []);

  const onKennitalaInput = useCallback(({ value }) => {
    setKennitala(value);
  }, []);

  const onNumberInput = useCallback(({ value }) => {
    setNumber(value);
  }, []);

  useEffect(() => {
    setField('combined', {
      phoneValidation: { triggerOn: ['submit'] },
    });
    setField('name', {
      required: { triggerOn: ['submit'] },
    });
    setField('kennitala', {
      required: { triggerOn: ['submit'] },
      minLength: { length: 10, triggerOn: ['submit', 'blur'] },
      maxLength: { length: 10, triggerOn: ['submit', 'blur'] },
    });
    setField('country', {
      required: { triggerOn: ['submit'] },
    });
    setField('phone', {
      required: { triggerOn: ['submit'] },
    });
  }, [customValidation]);

  useEffect(() => {
    field.combined.setEnable(true);
  }, [field.combined]);

  const options = useMemo(() => codes.map(({ countryCodes, isoCode2 }) => ({ id: `+${countryCodes[0]}`, label: `${isoCode2} ( +${countryCodes[0]} )` })), []);

  const getInputs = () => {
    if (webEnv.indexOf('NO') > -1) {
      return (
        <div className="inputs">
          <TrackwellInput type="text" topLabel={localization.modalCaptainName} onInput={onNameInput} trackwellValidation={field.name} value={name} />
          <TrackwellInput type="select" topLabel={localization.modalCountry} onInput={selectCountry} options={options} trackwellValidation={field.country} value={country} />
          <TrackwellInput type="textnumber" topLabel={localization.modalNumber} onInput={onNumberInput} trackwellValidation={field.phone} value={number} valueRestrictorFunction={filterOutText} />
        </div>
      );
    } else {
      return (
        <div className="inputs">
          <TrackwellInput type="text" topLabel={localization.modalCaptainName} onInput={onNameInput} trackwellValidation={field.name} value={name} />
          <TrackwellInput type="textnumber" topLabel="Kennitala" onInput={onKennitalaInput} trackwellValidation={field.kennitala} value={kennitala} valueRestrictorFunction={filterOutText} />
          <TrackwellInput type="select" topLabel={localization.modalCountry} onInput={selectCountry} options={options} trackwellValidation={field.country} value={country} />
          <TrackwellInput type="textnumber" topLabel={localization.modalNumber} onInput={onNumberInput} trackwellValidation={field.phone} value={number} valueRestrictorFunction={filterOutText} />
        </div>
      );
    }
  };

  return (
    <div className="addCaptainDialog">
      {getInputs()}
      <TrackwellExpandableDropdown
        className="triggerValidation"
        isOpen={typeof field.combined !== 'undefined' && !field.combined.valid}
      >
        {(typeof field.combined !== 'undefined' && !field.combined.valid) && field.combined.message}
      </TrackwellExpandableDropdown>
      <div className="submit">
        <TrackwellButton onClick={addCaptain} text={localization.modalConfirm} />
      </div>
    </div>
  );
});

AddCaptainDialog.defaultProps = {

};

AddCaptainDialog.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default AddCaptainDialog;
