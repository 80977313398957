import React, {
  memo, useState, useEffect, useMemo, useCallback, useRef,
} from 'react';
import './ownerDetail.scss';
import { TrackwellExpandable } from '@trackwell/trackwell-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import VesselDetail from '../../component/vesselDetail/vesselDetail';
import pricelist from './pricelist.json';
import { getLocalization } from '../../lang/lang';

const fetchVessels = async (ownerId) => {
  // eslint-disable-next-line no-restricted-globals
  const { vessels } = await axios.get(`${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/vessels/`, {
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + sessionStorage.getItem('token') },
  }).then(({ data }) => data).catch(() => ({ vessels: [] }));
  return vessels;
};

const fetchVesselDetail = async (ownerId, vesselId) => {
  // eslint-disable-next-line no-restricted-globals
  const detail = await axios.get(`${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/vessels/${vesselId}/`, {
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + sessionStorage.getItem('token') },
  }).then(({ data }) => data).catch(() => ({ detail: [] }));

  // todo remove this!
  for (let i = 0; i < pricelist.length; i += 1) {
    if (pricelist[i]['Skr.nr.'] === Number.parseInt(detail.registration_id, 10)) {
      detail.landings = pricelist[i]['Fjöldi landana'];
      if (typeof detail.landings === 'undefined') {
        detail.quote = undefined;
      } else if (detail.landings <= 5) {
        detail.quote = 0;
      } else if (detail.landings >= 6 && detail.landings <= 15) {
        detail.quote = '5.600';
      } else if (detail.landings >= 16 && detail.landings <= 25) {
        detail.quote = '11.200';
      } else if (detail.landings >= 26 && detail.landings <= 35) {
        detail.quote = '22.400';
      } else if (detail.landings >= 36 && detail.landings <= 50) {
        detail.quote = '33.600';
      } else if (detail.landings >= 51 && detail.landings <= 80) {
        detail.quote = '44.800';
      } else if (detail.landings >= 81) {
        detail.quote = '67.200';
      } else {
        detail.quote = undefined;
      }
      break;
    }
  }
  return detail;
};

const OwnerDetail = memo(({ ownerId }) => {
  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  const [vessels, setVessels] = useState(null);
  const [vesselDetail, setVesselDetail] = useState([]);
  const { current: alreadyOpen } = useRef([]);
  const expandQueue = useRef();

  useEffect(async () => {
    setVessels(await fetchVessels(ownerId));
  }, [ownerId]);

  // eslint-disable-next-line no-return-await
  const getVesselDetail = useCallback(async (vesselId) => await fetchVesselDetail(ownerId, vesselId), [ownerId]);

  const refreshDetail = useCallback(async (index) => {
    const newDetail = [...vesselDetail];
    newDetail[index] = await getVesselDetail(vessels[index].registration_id);
    setVesselDetail(newDetail);
  }, [vessels, vesselDetail]);

  const vesselList = useMemo(() => {
    const list = [];
    const v = vessels === null ? [] : vessels;
    for (let i = 0; i < v.length; i += 1) {
      const onToggle = async (toggle, isOpen) => {
        const cachedToggle = () => {
          alreadyOpen[i] = isOpen;
          toggle();
        };
        if (typeof vesselDetail[i] === 'undefined') {
          const newDetail = [...vesselDetail];
          newDetail[i] = await getVesselDetail(vessels[i].registration_id);
          expandQueue.current = () => {
            cachedToggle();
          };
          setVesselDetail(newDetail);
        } else {
          cachedToggle();
        }
      };
      list.push(
        <TrackwellExpandable
          title={`${vessels[i].registration_id} - ${vessels[i].name}`}
          startOpen={typeof alreadyOpen[i] !== 'undefined' && alreadyOpen[i]}
          onToggle={onToggle}
          key={vessels[i].registration_id}
        >
          {typeof vesselDetail[i] !== 'undefined' && (
            <VesselDetail
              ownerId={ownerId}
              callsign={vesselDetail[i].callsign}
              vesselId={vesselDetail[i].registration_id}
              captains={vesselDetail[i].captains}
              landings={vesselDetail[i].landings}
              quote={vesselDetail[i].quote}
              refreshDetail={() => refreshDetail(i)}
            />
          )}
        </TrackwellExpandable>,
      );
    }
    return list;
  }, [ownerId, vessels, vesselDetail, getVesselDetail]);

  useEffect(() => {
    if (expandQueue.current) {
      expandQueue.current();
      expandQueue.current = undefined;
    }
  });

  return (
    <div className="ownerDetail">
      <h1>
        {localization.manageShip}
      </h1>
      <div className="vesselList">
        {vesselList}
      </div>
      {vessels === null && (
        <div className="loading">
          <div />
          <div />
          <div />
        </div>
      )}
    </div>
  );
});

OwnerDetail.defaultProps = {
  ownerId: undefined,
};

OwnerDetail.propTypes = {
  ownerId: PropTypes.any,
};

export default OwnerDetail;
