import React, {
  memo, useEffect, useMemo, useRef, useState,
} from 'react';
import './contactInfo.scss';
import { getLocalization } from '../../lang/lang';

const ContactInfo = memo(() => {
  const wrapperRef = useRef();
  const [style, setStyle] = useState(undefined);

  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (wrapperRef.current.previousElementSibling.firstChild !== null) {
        setStyle({ width: wrapperRef.current.previousElementSibling.firstChild.offsetWidth, opacity: 1 });
      }
    }, 0);
  });

  return (
    <div ref={wrapperRef} className="contactInfo" style={style}>
      <div className="compName">{localization.contactName}</div>
      <div className="compAdd">{localization.contactAddress}</div>
      <div className="compPhone">
        <a href={`tel:${localization.contactPhone}`}>{localization.contactPhone}</a>
      </div>
      <div className="hafsynEmail"><a href={`mailto:${localization.contactEmail}`}>{localization.contactEmail}</a></div>
    </div>
  );
});

export default ContactInfo;
