import React, {
  memo, useCallback, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './vesselDetail.scss';
import {
  TrackwellButton, TrackwellDialogContext, TrackwellTooltipContext, TrackwellModalContext, TrackwellTable,
} from '@trackwell/trackwell-components';
import axios from 'axios';
import PhoneNumberDialog from './modal/phoneNumberDialog';
import AddCaptainDialog from './modal/addCaptainDialog';
import { getEnv, getLocalization } from '../../lang/lang';

const removeCaptain = async (ownerId, vesselId, govId) => {
  // eslint-disable-next-line no-restricted-globals
  const del = await axios.delete(`${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/vessels/${vesselId}/`, {
    data: { gov_id: govId }, headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') },
  }).then(() => true).catch(() => false);
  return del;
};

const updateCaptain = async (ownerId, vesselId, captainId, inputName, phone) => {
  const add = await axios.put(
    // eslint-disable-next-line no-restricted-globals
    `${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/vessels/${vesselId}/`,
    { name: inputName, gov_id: captainId, phone_numbers: Array.isArray(phone) ? phone : typeof phone !== 'undefined' ? [phone] : [] },
    { headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') } },
  ).then(() => true).catch(() => false);
  return add;
};

const VesselDetail = memo(({
  ownerId, vesselId, captains, refreshDetail, landings, quote,
}) => {
  const webEnv = useMemo(() => {
    return getEnv();
  }, []);
  const localization = useMemo(() => {
    return getLocalization();
  }, []);
  const tooltip = useContext(TrackwellTooltipContext);
  const dialog = useContext(TrackwellDialogContext);
  const modal = useContext(TrackwellModalContext);

  const unassign = useCallback((e, variable) => {
    dialog.open('confirm', (<div className="unassignDialogText">{localization.vesselRemoveConfirm}</div>), {
      onConfirm: async () => {
        if (await removeCaptain(ownerId, vesselId, variable)) {
          refreshDetail();
        } else {
          dialog.open('okay', (<div className="unassignDialogText">{localization.vesselRemoveFailed}</div>));
        }
      },
    });
  }, [refreshDetail]);

  const addNumber = useCallback((e, { kt, pn, name }) => {
    const onSuccess = async (phone) => {
      pn.push(phone);
      modal.close();
      if (await updateCaptain(ownerId, vesselId, kt, name, pn)) {
        refreshDetail();
      }
    };
    modal.open(localization.vesselAddNumber, (<PhoneNumberDialog onSuccess={onSuccess} />));
  }, [ownerId, vesselId, refreshDetail]);

  const addCaptain = useCallback(async () => {
    const onSuccess = async ({ name, kennitala, phone }) => {
      modal.close();
      if (await updateCaptain(ownerId, vesselId, kennitala, name, phone)) {
        refreshDetail();
      }
    };
    modal.open(localization.vesselAddCaptain, (<AddCaptainDialog onSuccess={onSuccess} />));
  }, [ownerId, vesselId, refreshDetail]);

  const captainList = useMemo(() => {
    const list = [];
    for (let i = 0; i < captains.length; i += 1) {
      const pn = [];
      for (let j = 0; j < captains[i].known_phone_numbers.length; j += 1) {
        pn.push(
          <div key={captains[i].known_phone_numbers[j]}>
            <a href={`tel:${captains[i].known_phone_numbers[j]}`}>{captains[i].known_phone_numbers[j]}</a>
          </div>,
        );
      }
      const addNumberReturn = { kt: captains[i].gov_id, pn: [...captains[i].known_phone_numbers], name: captains[i].name };
      list.push(
        <div className="captain" key={captains[i].gov_id}>
          <div className="name">
            {typeof captains[i].name === 'undefined' || captains[i].name.length === 0 || captains[i].name === 'Unknown_name' ? `${localization.vesselCaptain} #${i + 1}` : captains[i].name}
            <TrackwellButton className="unassign" text={localization.vesselDeregister} variable={captains[i].gov_id} onClick={unassign} />
          </div>
          <div className="phoneNumbers">
            {pn}
            (
            {localization.vesselNumPhones}
            {' '}
            {captains[i].total_phone_numbers}
            )
            <TrackwellButton onClick={addNumber} text={localization.vesselAddNumber} variable={addNumberReturn} />
          </div>
        </div>,
      );
    }
    return list;
  }, [captains]);

  const feeWithTooltip = useCallback(() => {
    const header = {
      left: {
        label: 'Áætlaður fjöldi landana',
      },
      right: {
        label: 'Ársgjald',
      },
    };
    const style = {
      position: 'absolute',
      marginTop: -9,
      marginLeft: 0,
      transform: 'scale(0.75)',
    };
    return tooltip.createElement(
      <TrackwellTable
        rowHeight={30}
        height={285}
        defaultRows={[
          { order: 0, left: '0 - 5', right: '0 kr' },
          { order: 1, left: '6 - 15', right: '5.600 kr' },
          { order: 2, left: '16 - 25', right: '11.200 kr' },
          { order: 3, left: '26 - 35', right: '22.400 kr' },
          { order: 4, left: '36 - 50', right: '33.600 kr' },
          { order: 5, left: '51 - 80', right: '44.800 kr' },
          { order: 6, left: '81+', right: '67.200 kr' },
        ]}
        label="Verðskrá 2022"
        header={header}
        defaultSort={{
          by: 'order',
          ascending: false,
        }}
        showFooter={false}
        uniqueId="order"
      />,
      'Ársgjald:',
      <span className="questionMark" style={style}>?</span>,
      null,
      null,
      'elemCoord',
    );
  }, []);

  const missingDataTooltip = useCallback(() => tooltip.createElement(
    'Vantar Gögn',
    '0?',
    <span className="questionMark" style={{ margin: 0 }}>?</span>,
    null,
    null,
    'elemCoord',
  ), []);

  const feeSegment = useCallback(() => {
    if (webEnv.indexOf('IS') > -1) {
      return (
        <div className="yearFee">
          <div>
            <span className="header">Fjöldi Landana:</span>
            {typeof landings !== 'undefined' ? landings : missingDataTooltip()}
          </div>
          <div>
            <span className="header">{feeWithTooltip()}</span>
            <span className="price">
              {typeof quote !== 'undefined' ? `${quote} kr.` : missingDataTooltip()}
            </span>
          </div>
        </div>
      );
    }
  }, []);

  return (
    <div className="vesselDetail">
      {feeSegment()}
      <span className="header">{localization.vesselRegCaptain}</span>
      <div className="list">
        {captainList}
      </div>
      <TrackwellButton onClick={addCaptain} text={localization.vesselAddCaptain} />
    </div>
  );
});

VesselDetail.defaultProps = {
  captains: [],
  landings: undefined,
  quote: undefined,
};

VesselDetail.propTypes = {
  ownerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  vesselId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  captains: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    gov_id: PropTypes.string,
    known_phone_numbers: PropTypes.arrayOf(PropTypes.string),
    total_phone_numbers: PropTypes.number,
  })),
  refreshDetail: PropTypes.func.isRequired,
  landings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quote: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default VesselDetail;
