import React, {
  memo, useState, useCallback, useEffect, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import './noOwner.scss';
import { TrackwellInput, TrackwellButton, useTrackwellValidation } from '@trackwell/trackwell-components';
import axios from 'axios';
import EntryContext from '../../context/entry';
import { getLocalization } from '../../lang/lang';

const NoOwner = memo(({ ownerId }) => {
  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  const contextAction = useContext(EntryContext);
  const [email, setEmail] = useState();

  const { field, setField, submit } = useTrackwellValidation();

  useEffect(() => {
    setField('email', {
      required: { triggerOn: ['submit'] },
      email: { emptyIsValid: false, triggerOn: ['blur', 'submit'] },
    });
  }, [setField]);

  const onEmailInput = useCallback(({ value }) => {
    setEmail(value);
  }, []);

  const onSubmit = useCallback(async () => {
    if (await submit()) {
      await axios.put(
        // eslint-disable-next-line no-restricted-globals
        `${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/`,
        { email },
        { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + sessionStorage.getItem('token') } },
      )
        .then(() => {
          // eslint-disable-next-line no-restricted-globals
          sessionStorage.setItem('noEmail', 'false');
          contextAction.updateView();
        });
    }
  }, [email, ownerId]);

  return (
    <div className="noOwner">
      <h1>
        {localization.noEmailEmail}
      </h1>
      <span className="missingEmail">
        {localization.noEmailInstructions}
      </span>
      <div className="inputs">
        <TrackwellInput
          topLabel={localization.noEmailEmail}
          placeholder={localization.noEmailFormPlaceholder}
          value={email}
          onInput={onEmailInput}
          trackwellValidation={field.email}
        />
        <TrackwellButton onClick={onSubmit} text={localization.noEmailFormSubmit} />
      </div>
    </div>
  );
});

NoOwner.defaultProps = {
  ownerId: undefined,
};

NoOwner.propTypes = {
  ownerId: PropTypes.any,
};

export default NoOwner;
