import enJson from './en.json';
import isJson from './is.json';

const lang = 'IS';

const getLocalization = () => {
  // react is super-dumb and doesn't have a way to configure server-side modes
  // this is crazy, but I have things to get done, so we're just using sed to replace this value
  // just before startup using sed in the docker script
  // using indexof to guard against invisible characters like newlines or byte order marks
  if (lang.indexOf('NO') > -1) {
    return enJson;
  } else {
    return isJson;
  }
};

const getEnv = () => {
  return lang;
};

export { getLocalization, getEnv };
