import React, { memo, useCallback, useMemo } from 'react';
import './noToken.scss';
import { getEnv, getLocalization } from '../../lang/lang';

const NoToken = memo(() => {
  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  const webEnv = useMemo(() => {
    return getEnv();
  }, []);

  const doLogin = useCallback((e) => {
    e.preventDefault();
    window.location.assign(localization.ssoAuthorizationURL);
  }, [localization]);

  const getLoginGraphic = () => {
    return (
      <a className="iskey-login" href="./" onClick={doLogin}>
        <img src={localization.ssoLogo} alt={localization.ssoLogoAlt} />
        {localization.ssoLoginText}
        {' '}
        <strong>{localization.ssoLoginProviderName}</strong>
      </a>
    );
  };

  const getIcekeyInfo = () => {
    if (webEnv.indexOf('IS') > -1) {
      return (
        <div>
          <a className="iskey-get" href="https://island.is/islykill" target="_blank" rel="noreferrer">
            Sækja um Íslykil
          </a>
          <span className="iskey-disclaimer">
            Nota Íslykil sem er tengdur Útgerðinni ef hann er ekki til er
            hægt að sækja um hann hér.
          </span>
        </div>
      );
    }
  };

  const getLoginButton = () => {
    return (
      <div className="iskey">
        <h3>
          {localization.ssoLoginInstructions}
          {' '}
        </h3>
        {getLoginGraphic()}
        <span className="iskey-info">
          {localization.ssoInfoSlug}
        </span>
        {getIcekeyInfo()}
        <a className="iskey-privacy" href={localization.loginTOSLink} target="_blank" rel="noreferrer">
          {localization.loginTOS}
        </a>
      </div>
    );
  };

  return (
    <>
      <div className="topNoToken">
        <img src={localization.loginProductLogo} alt={localization.loginProductLogoAlt} />
        <h3 style={{ 'padding-left': '25px' }}>
          {localization.loginProductDescription}
        </h3>
      </div>
      <div className="noToken">
        {getLoginButton()}
        <div className="appBox">
          <h3>{localization.loginAppTitle}</h3>
          <span>
            {localization.loginAppInstructions}
          </span>
          <div className="appLinks">
            <a href={localization.loginIOSStoreLink}>
              <img src="/App_Store_(iOS).svg" width={200} height={200} alt="Apple app store" />
            </a>
            <a href={localization.loginPlayStoreLink}>
              <img src="/Google_Play-Icon.svg" width={200} height={200} alt="Google play store" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
});

export default NoToken;
