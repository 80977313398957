import React, {
  memo, useEffect, useState, useCallback, useMemo,
} from 'react';
import axios from 'axios';
import {
  TrackwellDialog, TrackwellModal, TrackwellTooltip, useDynamicRef,
} from '@trackwell/trackwell-components';
import NoToken from './page/noToken/noToken';
import NoOwner from './page/noOwner/noOwner';
import OwnerDetail from './page/ownerDetail/ownerDetail';
import EntryContext from './context/entry';
import ContactInfo from './component/contactInfo/contactInfo';
import { getEnv, getLocalization } from './lang/lang';

const fetchOwnerId = () => {
  const ownerId = sessionStorage.getItem('ownerID'); // 6608922069; // todo: get ownerId from authorization body, if not found return undefined
  return ownerId;
};

const fetchOwnerEmail = async (ownerId) => {
  const get = sessionStorage.getItem('noEmail');
  if (get !== null && get === 'true') {
    return false;
  }
  // eslint-disable-next-line no-restricted-globals
  const ownerEmail = await axios.get(`${location.protocol}//${location.hostname}:${location.port}/owners/${ownerId}/`, {
    headers: { Authorization: 'Bearer ' + sessionStorage.getItem('token') },
  }).then(({ data }) => data).catch(() => undefined);
  return ownerEmail;
};

const Entry = memo(() => {
  const [Page, setPage = useState] = useState(null);
  const [ownerId, setOwnerId = useState] = useState(undefined);

  const localization = useMemo(() => {
    return getLocalization();
  }, []);

  const webEnv = useMemo(() => {
    return getEnv();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const override = new URL(location.href).searchParams.get('twOverrideToken');
    if (override !== null) {
      const decoded = atob(override);
      const split = decoded.split(':');
      if (split[0] === 'twOverride') {
        sessionStorage.setItem('ownerID', split[2]);
        sessionStorage.setItem('token', override);
      }
      // eslint-disable-next-line no-restricted-globals
      const hrefNoParams = location.href.split('?')[0];
      window.location.replace(hrefNoParams);
    }
    // eslint-disable-next-line no-restricted-globals
    const get = new URL(location.href).searchParams.get('noEmail');
    if (get !== null && get === 'true') {
      sessionStorage.setItem('noEmail', 'true');
    }
    setOwnerId(fetchOwnerId());
  }, []);

  const updateView = useCallback(async () => {
    if (ownerId) {
      // token has ownerId
      const ownerEmail = await fetchOwnerEmail(ownerId);
      if (ownerEmail) {
        // owner has email
        setPage(OwnerDetail);
      } else {
        // owner doesn't have email
        setPage(NoOwner);
      }
    } else {
      // doesn't have token
      // if the user has a query param with the OTP, try to fetch it
      // if it succeeds, set the header and stuff then refresh
      // if it 404's, hit the notoken page

      const otp = new URLSearchParams(window.location.search).get('otp');
      if (otp !== null) {
        const response = await fetch('/auth/' + otp + '/');
        if (!response.ok) {
          setPage(NoToken);
        } else {
          const data = await response.json();
          setOwnerId(data.govID);
          sessionStorage.setItem('ownerID', data.govID);
          sessionStorage.setItem('token', data.token);
          const ownerEmail = await fetchOwnerEmail(ownerId);
          if (ownerEmail) {
            // owner has email
            setPage(OwnerDetail);
          } else {
            // owner doesn't have email
            setPage(NoOwner);
          }
        }
      } else {
        setPage(NoToken);
      }
    }
  }, [ownerId]);

  useEffect(() => {
    updateView();
  }, [updateView]);

  const contextActions = useDynamicRef({
    updateView, setOwnerId,
  });

  const getHeaderLogo = () => {
    if (webEnv.indexOf('NO') < 0) {
      return <img src={localization.headerLogo} alt={localization.headerLogoAlt} />;
    }
  };

  return (
    <TrackwellTooltip>
      <TrackwellDialog>
        <TrackwellModal>
          <EntryContext.Provider value={contextActions}>
            <div id="entry">
              <div id="header">
                <div>
                  { getHeaderLogo() }
                </div>
              </div>
              <div id="body">
                {Page !== null && (<Page ownerId={ownerId} />)}
              </div>
              <ContactInfo />
            </div>
          </EntryContext.Provider>
        </TrackwellModal>
      </TrackwellDialog>
    </TrackwellTooltip>
  );
});

export default Entry;
